// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-on-the-go-english-class-js": () => import("./../../../src/pages/portfolio/OnTheGoEnglishClass.js" /* webpackChunkName: "component---src-pages-portfolio-on-the-go-english-class-js" */),
  "component---src-pages-portfolio-sang-nam-gan-js": () => import("./../../../src/pages/portfolio/SangNamGan.js" /* webpackChunkName: "component---src-pages-portfolio-sang-nam-gan-js" */),
  "component---src-pages-portfolio-sang-nam-gan-old-js": () => import("./../../../src/pages/portfolio/SangNamGanOld.js" /* webpackChunkName: "component---src-pages-portfolio-sang-nam-gan-old-js" */),
  "component---src-pages-reading-list-js": () => import("./../../../src/pages/reading-list.js" /* webpackChunkName: "component---src-pages-reading-list-js" */)
}

